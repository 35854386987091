.root
  height: 100%
  background: $white
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08)
  // overflow: auto

.container
  display: flex
  // overflow: auto
  height: 100%
  max-height: 100%

.list
  padding: 15px 8px 15px 0
  min-width: 200px
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.08)
  overflow: auto

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: transparent

  &::-webkit-scrollbar-thumb
    background-color: #eee

    &:hover
      background-color: #dadada

.item
  & + &
    margin-top: 8px

.link.link
  display: flex
  align-items: center
  padding: 8px 8px 8px 0
  color: $black
  text-decoration: none

  &:hover
    text-decoration: none
    color: $green

    .icon
      fill: $green

.linkStateActive.linkStateActive
  color: $green

  .icon
    fill: $green

.arrow
  flex-shrink: 0
  margin-left: auto

.nameBlock
  display: flex
  align-items: center

.icon
  margin-right: 16px

.hidden.hidden
  display: none
