.root
  position: relative

.button.button
  display: flex
  align-items: center
  width: fit-content
  height: auto

.avatar
  border-radius: 50%

.arrow
  fill: $black
  stroke: $black
  transform: rotate(90deg)
  margin-left: 8px
  transition: .2s transform

.arrowStateOpen
  transform: rotate(-90deg)

.dropdownOuter
  position: absolute
  bottom: -8px
  right: 0
  display: flex
  justify-content: flex-end
  transition: opacity .12s, transform .12s, visibility .12s
  transform: translateY(calc(100% - 3px))
  visibility: hidden
  opacity: 0

.dropdownOpened
  visibility: visible
  transform: translateY(100%)
  opacity: 1

.list
  flex-shrink: 0
  position: relative
  background-color: $white
  display: flex
  flex-direction: column
  padding: 10px 0
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgba(0, 0, 0, 0.08)

  &:after
    content: ""
    position: absolute
    right: 3px
    top: -4px
    width: 0
    height: 0
    border-style: solid
    border-width: 0 5px 5px 5px
    border-color: transparent transparent $white transparent
