.desktopHeaderHolder
  display: none
  position: relative
  height: 78px
  z-index: 200
  background-color: $light-grey

  &:before
    content: ''
    display: block
    height: 78px

.desktopHeader
  position: relative
  display: flex
  align-items: center
  height: 78px
  background-color: $light-grey
  transform: translateY(-100%)
  transition: transform 200ms

.desktopHeaderStateSticky
  position: fixed
  top: 0
  left: 0
  right: 0
  transform: translateY(0)

.secondaryDesktopHeader
  display: none
  position: relative
  background-color: $white
  border-bottom: 1px solid $light-grey

.desktopCatalogMenu
  position: absolute
  top: 100%
  left: 0
  width: 100%
  z-index: $desktop-navbar-z-index
  border-top: 1px solid $light-grey
  background-color: $white
  visibility: hidden
  opacity: 0
  overflow: hidden
  transition: opacity .15s, transform .15s, visibility .15s

.desktopCatalogMenuStateOpen
  visibility: visible
  transform: translateY(0)
  opacity: 1

.desktopCatalogMenuContainer
  height: 100%

.mobileHeaderHolder
  height: 48px

.mobileHeader
  height: 48px
  position: fixed
  top: 0
  left: 0
  right: 0
  z-index: $mobile-top-navbar-z-index
  background-color: $light-grey

.mobileBottomBar
  height: 48px
  position: fixed
  left: 0
  right: 0
  bottom: 0
  z-index: $mobile-bottom-navbar-z-index

@media ($min-desktop)  
  .desktopHeaderHolder
    display: block

  .secondaryDesktopHeader
    display: block

  .mobileHeaderHolder, .mobileBottomBar
    display: none
