.root
  display: flex

.langForm + .langLink
  display: flex
  align-items: flex-start

  &::before
    content: "|"


.langLink + .langForm
  display: flex
  align-items: flex-start


  &::before
    content: "|"

.langLink
  text-decoration: none

  &:hover
    color: #7d7d7d

.langForm[data-lang]
  display: flex
  align-items: flex-start

  &::after
    content: attr(data-lang)
    display: inline-block
    padding: 1px 6px
    color: #8e8e8e
    cursor: default
    font-weight: 500

.newLang
  border: none
  font-weight: 500
  cursor: pointer
  outline: none
  background-color: transparent
  transition: color .2s
  padding: 1px 6px
  display: inline-block

  &:hover
    color: $green

.delimiter
  cursor: default
