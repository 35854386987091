.htmlOpen,
.bodyOpen
  width: 100%

.overlay
  z-index: $mobile-sidebar-z-index
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  transition: .3s background-color
  background-color: transparent

  &.afterOpen
    background-color: rgba(0, 0, 0, 0.4)

  &.beforeClose
    background-color: transparent

.root
  position: absolute
  top: 0
  bottom: 0
  left: 0
  max-height: 100%
  overflow: auto
  width: 288px
  background-color: $white
  outline: none
  transition: transform .3s
  transform: translateX(-100%)

  &.afterOpen
    transform: translateX(0)

  &.beforeClose
    transform: translateX(-100%)

.head
  position: sticky
  top: 0
  height: 48px
  background-color: $very-light-pink
  display: flex
  padding-right: 16px

.activeLevel
  display: flex
  align-items: center
  font-weight: 500
  cursor: pointer
  width: 100%
  padding-left: 16px

.arrowBack
  width: 24px
  height: 24px
  transform: rotate(180deg)
  margin-right: 16px
  fill: #000
  stroke: #000

.leftSide
  display: flex
  align-items: center
  flex-grow: 1

.burger
  display: flex
  align-items: center
  width: 20px
  height: 16px
  padding: 12px 16px
  cursor: pointer
  box-sizing: content-box

.rightSide
  display: flex
  align-items: center

.item
  & + &
    margin-top: 4px

.levelLink.levelLink
  display: flex
  justify-content: space-between
  align-items: center
  height: 48px
  padding: 0 16px
  border-bottom: 1px solid #f0f0f0
  cursor: pointer
  font-weight: 500
  color: $black

.icon
  margin-right: 16px

.link.link
  display: flex
  align-items: center
  height: 48px
  padding: 0 16px
  border-bottom: 1px solid #f0f0f0
  cursor: pointer

.linkThemePink.linkThemePink
  color: $pastel-red

  &:hover
    color: $pastel-red
  
  .icon
    fill: $pastel-red

.iconTypeSocialFeed
  stroke: $purple

