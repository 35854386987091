.list
  flex-shrink: 0
  position: relative
  background-color: $white
  display: flex
  flex-direction: column
  padding: 10px 0
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), inset 0 1px 0 rgba(0, 0, 0, 0.08)

  &:after
    content: ""
    position: absolute
    right: 3px
    top: -4px
    width: 0
    height: 0
    border-style: solid
    border-width: 0 5px 5px 5px
    border-color: transparent transparent $white transparent

.item
  //

.link.link
  display: flex
  align-items: center
  border-bottom: 1px solid #eee
  padding: 20px 16px
  text-decoration: none
  box-sizing: border-box

.icon
  margin-right: 16px

@media ($min-desktop)

  .link.link
    padding: 7px 16px
    border-bottom: none
