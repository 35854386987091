.root
  display: flex
  justify-content: space-between
  width: 100%
  background-color: $white
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2)

.slot.slot
  position: relative
  display: flex
  justify-content: center
  align-items: center
  flex-grow: 1

.slotStateActive.slotStateActive
  .icon
    fill: $green

.icon
  width: 24px
  height: 24px
  fill: $black

.testimonialsIcon
  padding-top: 2px

.unreadMessagesCount
  position: absolute
  left: 50%
  top: 5px
  background-color: $red
  font-size: 12px
  border-radius: 4px
  padding: 1px 3px
  color: $white

.image
  width: 32px
  height: 32px
  border-radius: 100%

.ripple.ripple
  position: relative
  overflow: hidden
  transform: translate3d(0, 0, 0)
  -webkit-tap-highlight-color: transparent
  height: auto
  width: fit-content

  &:after
    content: ""
    display: block
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    pointer-events: none
    background-image: radial-gradient(circle, #000 10%, transparent 10%)
    background-repeat: no-repeat
    background-position: 50%
    transform: scale(10,10)
    opacity: 0
    transition: transform .5s, opacity 1s

  &:active
    &:after
      transform: scale(0,0)
      opacity: 0.2
      transition: 0s

.addProductButton.addProductButton
  width: 48px
  height: 48px
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15)
  background-color: $white
  border-radius: 100%
  display: flex
  justify-content: center
  align-items: center
  text-decoration: none
  transform: translateY(-5px)
  font-size: 24px
  font-weight: 300
