.root
  position: relative
  display: flex
  align-items: center
  gap: 24px
  z-index: 1

.logoSection
  flex-shrink: 0
  position: relative

.logoLink
  display: flex
  align-items: center
  position: relative
  top: -2px

.canary
  position: absolute
  top: 0
  right: 0
  transform: translateX(100%)

.canaryClose
  padding: 4px
  border-radius: 100%
  background-color: $pastel-red
  fill: #fff
  transition: background-color .2s

  &:hover
    background-color: #d2493c

.searchSection
  display: flex
  align-items: center
  gap: 24px
  flex-grow: 1
  max-width: 660px

.search
  flex-grow: 1

.addButton.addButton
  width: fit-content
  flex-shrink: 0

.userSection
  display: flex
  align-items: center
  margin-left: auto
  gap: 24px
