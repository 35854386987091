.root
  display: flex
  align-items: center
  justify-content: space-between
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.05)

.backButton.backButton
  width: fit-content
  height: auto
  padding: 11px 8px 11px 16px
  transform: rotate(-180deg)
  flex-shrink: 0

.container
  display: flex
  align-items: center
  flex-grow: 1

.burgerButton.burgerButton
  width: fit-content
  height: auto
  padding: 12px 16px

.logoLink
  display: flex
  align-items: center
  position: relative
  top: -3px

.logo
  fill: $black

.searchButton.searchButton
  width: fit-content
  height: auto
  padding: 12px

.fastLinks
  display: flex
  align-items: center
  gap: 16px
