.root
  display: flex
  overflow: auto

  .link.link
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 10px
    margin-bottom: 10px
    color: $black
    text-decoration: none

    &:hover
      text-decoration: none
      color: $green-hover

.arrow
  flex-shrink: 0
  width: 24px
  height: 24px

.hovered.hovered
  color: $green-hover

.links
  padding: 15px 8px 15px 15px
  width: 150px
  max-width: 150px
  overflow-y: auto
  overflow-x: hidden
  border-right: 1px solid #eee
  overflow-wrap: anywhere

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: transparent

  &::-webkit-scrollbar-thumb
    background-color: #eee

    &:hover
      background-color: #dadada

.hidden.hidden
  display: none