.root
  display: flex
  align-items: center

.button.button
  height: auto
  width: fit-content

.delimiter
  margin: 0 8px
  color: $grey
