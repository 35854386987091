.root
  display: flex
  align-items: center

.catalogSelect
  width: 60%

.form
  flex-grow: 1
  display: flex
  align-items: center
  height: 40px
  width: 100%
  border-top-right-radius: 8px
  border-bottom-right-radius: 8px
  background-color: $white

.inputHolder
  width: 100%

.input.input
  height: 30px

.submit
  cursor: pointer
  background: transparent
  border: none
  height: 24px
  padding: 0 8px 0 0
  margin-right: 8px
  fill: #7d7d7d

  &:hover
    fill: $green

.icon
  width: 24px
  height: 24px

