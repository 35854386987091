.list
  display: flex
  align-items: center
  gap: 16px
  z-index: $desktop-profile-actions-z-index

.item
  flex-shrink: 0

.link.link
  position: relative
  display: block

  &:hover
    .icon
      fill: $green

.icon.icon
  fill: $black

.linkStateActive.linkStateActive
  .icon.icon
    fill: $green

.counter
  position: absolute
  right: -10px
  top: -8px
  border-radius: 7px
  font-size: 12px
  font-weight: 500
  background-color: $green
  padding: 1px 5px
  box-sizing: border-box
  color: $black

.indicator
  position: absolute
  top: 2px
  right: 0
  display: inline-block
  width: 6px
  height: 6px
  border-radius: 50%
  background-color: $green
  border: 1px solid $white
