.htmlOpen,
.bodyOpen
  width: 100%

.overlay
  z-index: 90
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0

.root
  position: absolute
  top: 0
  bottom: 0
  left: 0
  max-height: 100%
  overflow: auto
  width: 100%
  background-color: $white
  outline: none
  transition: transform .3s
  transform: translateX(100%)
  padding-top: 48px // Top NavBar height

  &.afterOpen
    transform: translateX(0)
    padding-bottom: 50px

  &.beforeClose
    transform: translateX(100%)