.list
  display: flex
  flex-wrap: wrap
  align-items: center
  padding: 16px 0
  margin: -8px 0 0 -8px

.item
  margin: 8px 0 0 8px

.menuButton.menuButton
  width: fit-content
  height: auto
  padding: 8px 16px
  font-weight: 500
  font-size: 14px

.menuIcon
  margin-right: 12px
  fill: $black

.link.link
  display: flex
  align-items: center
  height: 40px
  color: $black
  text-decoration: none
  font-weight: 500
  font-size: 14px
  padding: 8px 16px
  border-radius: 8px
  box-sizing: border-box
  transition: background-color .2s, color .2s

  &:hover
    color: $black
    background-color: #eee
    text-decoration: none

.link.linkThemePurple
  color: $purple

  &:hover
    color: $purple
    background-color: #F3F1FE

.link.linkThemePink
  color: $pastel-red

  .icon
    fill: $pastel-red

  &:hover
    color: $pastel-red
    background-color: #fff1f6

.icon
  margin-right: 8px
